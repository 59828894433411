import React, { useState, useEffect } from 'react';
import "./NavBar.css";
import { NavTable } from "../../Data/Data";
import logo from "../../assets/logo.svg";
import hamburger from "../../assets/open-anv.svg";
import closenav from "../../assets/close.svg";
import { NavLink, useLocation } from 'react-router-dom';
import LanguageSwitcher from './LangueSwitcher/LangueSwitcher';
import { useTranslation } from 'react-i18next';

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const [showNav, setShowNav] = useState(false);
  const location = useLocation();

  const toggleNavItems = () => {
    setShowNav(!showNav);
  };

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const isCmsPage = pathSegments[1] === 'page';

    if (isCmsPage) {
      const slugLanguage = pathSegments[2].split('-')[0]; 
      if (slugLanguage && i18n.language !== slugLanguage) {
        i18n.changeLanguage(slugLanguage);
      }
    } else {
      const pathLanguage = pathSegments[1];
      const isValidPage = NavTable.some(navItem => navItem.href === pathSegments[2]);
      
      if (isValidPage) {
        if (pathLanguage && i18n.language !== pathLanguage) {
          i18n.changeLanguage(pathLanguage);
        }
      } else {
        // If the page is not recognized, do nothing and keep the last detected language
      }
    }
  }, [location.pathname, i18n]);

  const isHomePage = location.pathname === `/${i18n.language}/`;
  const isNoRoomPage = location.pathname === '/noRoom';
  const isSearchPage = location.pathname === '/searchCities';

  return (
    <>
      <nav className='NavBar-container d-flex '>
        <NavLink to={`/${i18n.language}/`}>
          <img src={logo} alt="company's logo" className='img-fluid' />
        </NavLink>
        <div className='d-flex align-items-center element-lang'>
          <div className={`menu-icon ${showNav && 'active'}`} onClick={toggleNavItems}>
            <img className='open-nav' src={hamburger} alt="open menu" />
            <img className='close-nav' src={closenav} alt="close menu" />
          </div>
          <div className={`nav-elements ${showNav && 'active'}`} onClick={toggleNavItems}>
            <div className='nav-list'>
              {NavTable.map((navItem, index) => (
                <NavLink 
                  key={index} 
                  className="List-items" 
                  to={`/${i18n.language}/${navItem.href}`}
                  end
                >
                  {t(`navbar.${navItem.name}`)}
                </NavLink>
              ))}
            </div>
          </div>
          <LanguageSwitcher />
        </div>
      </nav>
      <div className={`nav-divider ${isHomePage || isNoRoomPage || isSearchPage ? 'hide-on-home' : ''}`}></div>
    </>
  );
};

export default NavBar;
