import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';


i18n
  .use(initReactI18next) 
  .use(LanguageDetector) 
  .use(HttpApi) 
  .init({
    fallbackLng: 'fr', 
    supportedLngs: ['fr', 'en', 'es'], 
    detection: {
        order: ['path', 'cookie', 'localStorage', 'htmlTag',  'subdomain'],
        lookupFromPathIndex: 0,
        checkWhitelist: true,
        caches: ['cookie'],
    },
    backend: {
    loadPath  : '/locales/{{lng}}/translation.json', 
    },
    react: {
      useSuspense: true,
    },
    debug: false, 
  });

export default i18n;
